var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:{ 'container-fluid': true, 'sidebar-open': _vm.sidebarVisible }},[_c('div',[(!_vm.sidebarVisible)?_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.setSidebar}},[_vm._v("Vis menu")]):_vm._e()],1),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-10 col-md-11 col-12"},[_c('h1',[_vm._v(_vm._s(_vm.companyName))]),_c('p',[_vm._v(_vm._s(_vm.companyCvr))])])]),_c('NavigationSlide',{attrs:{"title":_vm.companyName,"undertitle":_vm.companyCvr,"nav":[
			{
				name: 'Company',
				params: '{ companyId: ' + _vm.id + '}',
				text: _vm.$t('companyBase.info'),
				icon: 'building',
				hidden: !_vm.$can('read', 'Company', 'CompanyInfo'),
			},
			{
				name: 'CompanyEmployeeList',
				params: '{ companyId: ' + _vm.id + '}',
				text: _vm.$t('companyBase.employeeList'),
				hidden: !_vm.$can('read', 'Company', 'Employees'),
				icon: 'users',
			},
			{
				name: 'CompanyPensionList',
				params: '{ companyId: ' + _vm.id + '}',
				text: _vm.$t('companyBase.pension'),
				icon: 'list-alt',
				hidden: !_vm.$can('read', 'Company', 'Pensions'),
			},
			{
				name: 'CompanyInsuranceList',
				params: '{ companyId: ' + _vm.id + '}',
				text: _vm.$t('companyBase.insurance'),
				icon: 'house-damage',
				hidden: !_vm.$can('read', 'Company', 'Insurances'),
			},
			{
				name: 'CompanyGroup',
				params: '{ companyId: ' + _vm.id + '}',
				text: 'Grupper',
				icon: 'user-friends',
				hidden: !_vm.$can('read', 'Company', 'Group'),
			},

			{
				name: 'CompanyKycList',
				params: '{ companyId: ' + _vm.id + '}',
				text: 'Ejerforhold',
				hidden: !_vm.$can('read', 'Company', 'CompanyAdmins'),
				icon: 'passport',
			},

			{
				name: 'CompanyAdvisor',
				params: '{ companyId: ' + _vm.id + '}',
				text: _vm.$t('companyBase.advisors'),
				icon: 'user-graduate',
				hidden: !_vm.$can('read', 'Company', 'CompanyAdvisors'),
			},

			{
				name: 'CompanyLogs',
				params: '{ companyId: ' + _vm.id + '}',
				text: _vm.$t('companyBase.logs'),
				icon: 'server',
				hidden: !_vm.$can('read', 'Company', 'Logs'),
			},
			{
				name: 'CompanySettings',
				params: '{ companyId: ' + _vm.id + '}',
				text: _vm.$t('companyBase.settings'),
				icon: 'cog',
				hidden: !_vm.$can('update', 'Company', 'Settings'),
			} ]},model:{value:(_vm.sidebarVisible),callback:function ($$v) {_vm.sidebarVisible=$$v},expression:"sidebarVisible"}}),(_vm.isReady)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('router-view')],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }